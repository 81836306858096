/* 修改全局滚动条样式 */
::-webkit-scrollbar {
  width: 4px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ant-menu-item-selected {
  color: #165dff !important;
}

.ant-layout-content {
  height: calc(100vh - 60px);
  background: #f2f3f5;
  padding: 16px !important;
}

.ant-layout-sider {
  background-color: #fff !important;
}

.ant-btn {
  border-radius: 12px;
}

.ant-modal {
  .ant-modal-content {
    border-radius: 24px;
  }
}
